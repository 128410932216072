import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { QueueActions } from './queue.actions';
import {
  DocumentData,
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerQueue } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { DialerQueueChanges } from 'src/app/shared/models/classes/call';

@Injectable()
export class QueueEffects {
  loadQueues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.loadQueues),
      mergeMap(({ agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const agencyIds = Array.isArray(agencyId) ? agencyId : [agencyId]

          const batchSize = 10
          const batches = agencyIds.reduce((acc, id, index) => {
            const batchIndex = Math.floor(index / batchSize)
            if (!acc[batchIndex]) {
              acc[batchIndex] = []
            }
            acc[batchIndex].push(id)
            return acc
          }, [] as string[][])

          for (const batch of batches) {
            httpsCallable(this.functions, 'queueList')({ agencyIds: batch }).then((result) => {
              console.log('queueList', result)

              const queues = (result.data as unknown[])?.map((q: any) => DialerQueue.fromJSON(q))

              subscriber.next(
                QueueActions.loadQueuesSuccess({ queues }),
              );
            }).catch((error) => {
              subscriber.next(QueueActions.loadQueuesFailure({ error }));
            })
          }
        }).pipe(
          catchError((error) =>
            of({ type: '[Queue API] Load Queues Error', error }),
          ),
        );
      }),
    );
  });

  loadQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.loadQueue),
      switchMap(({ queueId, agencyId }) => {
        return new Observable<Action>((subscriber) => {

          httpsCallable(this.functions, 'queueList')({ agencyId, queueId }).then((result) => {
            console.log('queueList single', result)

            const queues = (result.data as unknown[])?.map((q: any) => DialerQueue.fromJSON(q))

            const queue = queues?.[0]

            subscriber.next(
              QueueActions.loadQueueSuccess({ queue }),
            );
          }).catch((error) => {
            subscriber.next(QueueActions.loadQueueFailure({ error }));
          })
        }).pipe(
          catchError((error) =>
            of({ type: '[Queue API] Load Queues Error', error }),
          ),
        );
      }),
    );
  });

  addQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.addQueue),
      mergeMap(async ({ queue, agencyId }) => {
        console.log('addQueueAction', queue);
        try {
          const result = await httpsCallable(this.functions, 'queueUpdate')({ agencyId, queueId: queue.id, queue: DialerQueue.toJSON(queue) })
          console.log('queueUpdate add', result)

          const data = result.data as { queue: unknown }

          const resultQueue = data?.queue ? DialerQueue.fromJSON(data.queue) : null

          if (!resultQueue) {
            return QueueActions.addQueueFailure({ error: 'Queue not found' })
          }

          return QueueActions.addQueueSuccess({
            queue: DialerQueue.fromJSON({ ...resultQueue, agency: { id: agencyId }, }),
          }); // return new queue with id
        } catch (error) {
          return QueueActions.addQueueFailure({ error });
        }
      }),
    );
  });

  removeQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.removeQueue),
      mergeMap(async ({ queueId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'queues', queueId),
          );
          return QueueActions.removeQueueSuccess({ queueId }); // return removed queue's id
        } catch (error) {
          return QueueActions.removeQueueFailure({ error });
        }
      }),
    );
  });

  updateQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QueueActions.updateQueue),
      mergeMap(async ({ queueId, queue, agencyId, changes }) => {
        console.log('updateQueueAction', queueId, queue, changes);

        try {

          const parsed = DialerQueue.toJSON((queue as DialerQueue)) as { [key: string]: unknown }

          if (!this.authService.userInfo?.claims?.['dialerSAD']) {
            if (queue.restrict?.billableSettings) {
              delete parsed['billableSettings']
            }

            if (queue.restrict?.soldSettings) {
              delete parsed['soldSettings']
            }
            delete parsed['restrict']
          }

          const changesParsed = changes ? DialerQueueChanges.toJSON(changes) : undefined

          const result = await httpsCallable(this.functions, 'queueUpdate')({ agencyId, queueId: queue.id, queue: parsed, changes: changesParsed })
          console.log('queueUpdate update', result)

          const data = result.data as { queue: unknown }
          const resultQueue = data?.queue ? DialerQueue.fromJSON(data.queue) : null

          if (!resultQueue) {
            return QueueActions.updateQueueFailure({ error: 'Queue not found' })
          }

          return QueueActions.updateQueueSuccess({
            queueId,
            agencyId,
            queue: DialerQueue.fromJSON({ ...resultQueue, agencyId, agency: { id: agencyId }, }),
          }); // return new queue with id
        } catch (error) {
          return QueueActions.updateQueueFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private firestore: Firestore,
    private functions: Functions
  ) { }
}
