import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, firstValueFrom, from, of } from 'rxjs';
import { StaticActions } from './static.actions';
import {
  Firestore,
  collection,
  doc,
  getDocs,
  onSnapshot,
  Unsubscribe,
} from '@angular/fire/firestore';
import {
  Carrier,
  PermanentTag,
} from 'src/app/shared/models';
import { Action } from '@ngrx/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class StaticEffects {
  loadStaticCarriers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.loadStaticCarriers),
      mergeMap(() =>
        from(
          getDocs(collection(this.firestore, 'static/carrier/carriers'))
        ).pipe(
          map(snapshot => {
            const carriers = snapshot.docs.map(doc =>
              Carrier.fromJSON({
                ...doc.data(),
                id: doc.id,
              })
            );
            return StaticActions.loadStaticCarriersSuccess({ carriers });
          }),
          catchError(error =>
            of(StaticActions.loadStaticCarriersFailure({ error }))
          )
        )
      )
    );
  });

  private staticTagsSubscription: { [key: string]: Unsubscribe } = {};

  loadStaticTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.loadStaticTags),
      mergeMap(
        ({ agencyId }) =>
          new Observable<Action>(subscriber => {
            if (this.staticTagsSubscription[agencyId]) {
              return;
            }
            this.staticTagsSubscription[agencyId] = onSnapshot(
              doc(this.firestore, `agencies/${agencyId}/static/permanentTags`),
              doc => {
                const data = doc.data();

                const tags: PermanentTag[] = []

                if (data && data['byId']) {
                  for (const [id, tag] of Object.entries(data['byId'] || {})) {
                    tags.push(PermanentTag.fromJSON({
                      ...(tag as object),
                      id,
                    }))
                  }
                }

                subscriber.next(
                  StaticActions.loadStaticTagsSuccess({
                    agencyId,
                    tags,
                  })
                );
              },
              error => {
                console.error('error loading static tags', error)
                subscriber.error(StaticActions.loadStaticTagsFailure({ error }))
              }
            );
            return () => this.staticTagsSubscription[agencyId]();
          })
      )
    );
  });

  updateStaticTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.updateStaticTag),
      mergeMap(async ({ agencyId, tag }) => {
        try {
          const apiRes = await this.callUpdateApi(agencyId, tag)

          if (apiRes.success) {
            return StaticActions.updateStaticTagSuccess({
              agencyId,
              tag,
            });
          } else {
            throw new Error(apiRes.error?.message || 'Unknown error')
          }
        } catch (error) {
          return StaticActions.updateStaticTagFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  addStaticTag$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StaticActions.addStaticTag),
      mergeMap(async ({ agencyId, tag }) => {
        try {
          const apiRes = await this.callUpdateApi(agencyId, tag)

          if (apiRes.success && apiRes.tag) {
            return StaticActions.addStaticTagSuccess({
              agencyId,
              tag: apiRes.tag,
            });
          } else {
            throw new Error(apiRes.error?.message || 'Unknown error')
          }
        } catch (error) {
          return StaticActions.addStaticTagFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private http: HttpClient,
    private firestore: Firestore
  ) { }

  async callUpdateApi(agencyId: string, tag: PermanentTag) {
    const token = await this.authService.getIdToken()

    const url = `https://us-east1-${environment.firebase.projectId.split('-dialer')[0]}.cloudfunctions.net/platformDialerUpdateTag`

    const result = await firstValueFrom(this.http.post(url, {
      permanentTag: PermanentTag.toJSON(tag),
      agencyId,
    }, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })) as { success: boolean, tag: unknown, error?: string }

    if (result?.success) {
      return {
        success: true,
        tag: PermanentTag.fromJSON(result.tag),
      }
    } else {
      return {
        success: false,
        error: new Error(result.error || 'Unknown error'),
      }
    }
  }
}
