import { EnvironmentInjector, inject, Injectable, runInInjectionContext } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AgencyActions } from './agency.actions';
import {
  Firestore,
  Unsubscribe,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
} from '@angular/fire/firestore';
import { Agency } from 'src/app/shared/models';
import { Action } from '@ngrx/store';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable()
export class AgencyEffects {
  private actions$ = inject(Actions)
  private environmentInjector = inject(EnvironmentInjector)
  private firestore = inject(Firestore)
  private functions = inject(Functions)

  loadAgencies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.loadAgencies),
      mergeMap(() => {
        return new Observable<Action>(subscriber => {
          let unsubscribe: Unsubscribe;
          runInInjectionContext(this.environmentInjector, () => {
            unsubscribe = onSnapshot(
              collection(this.firestore, 'agencies'),
              snapshot => {
                const agencies = snapshot.docs.map(doc =>
                  Agency.fromJSON({ ...doc.data(), id: doc.id })
                );
                console.log('loadAgencies', agencies)
                subscriber.next(AgencyActions.loadAgenciesSuccess({ agencies }));
              },
              error => {
                console.log('loadAgenciesError', error)
                subscriber.next(AgencyActions.loadAgenciesFailure({ error }));
              }
            );

            // Provide a way of canceling and disposing the listener
            return unsubscribe;
          });
        }).pipe(
          catchError(error =>
            of({ type: '[Agency API] Load Agencies Error', error })
          )
        );
      })
    );
  });

  loadAgency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.loadAgency),
      mergeMap(({ agencyId }) => {
        return new Observable<Action>(subscriber => {
          console.log('loadAgency', agencyId)

          let unsubscribe: Unsubscribe;
          runInInjectionContext(this.environmentInjector, () => {
            unsubscribe = onSnapshot(
              doc(this.firestore, 'agencies', agencyId),
              snapshot => {
                const agency = Agency.fromJSON({
                  ...snapshot.data(),
                  id: snapshot.id,
                });
                console.log('loadAgency', agency)
                subscriber.next(AgencyActions.loadAgencySuccess({ agency }));
              },
              error => {
                console.error('loadAgencyError', error)
                subscriber.next(AgencyActions.loadAgencyFailure({ error }));
              }
            );
            return unsubscribe;
          });
        });
      })
    );
  });

  addAgency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.addAgency),
      mergeMap(async ({ agency }) => {
        try {
          const docRef = await addDoc(
            collection(this.firestore, 'agencies'),
            agency
          );
          return AgencyActions.addAgencySuccess({
            agency: Agency.fromJSON({ ...agency, id: docRef.id }),
          }); // return new agency with id
        } catch (error) {
          return AgencyActions.addAgencyFailure({ error });
        }
      })
    );
  });

  removeAgency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.removeAgency),
      mergeMap(async ({ agencyId }) => {
        try {
          await deleteDoc(doc(this.firestore, 'agencies', agencyId));
          return AgencyActions.removeAgencySuccess({ agencyId }); // return removed agency's id
        } catch (error) {
          return AgencyActions.removeAgencyFailure({ error });
        }
      })
    );
  });

  updateAgency$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AgencyActions.updateAgency),
      mergeMap(async ({ agencyId, agency }) => {
        return runInInjectionContext(this.environmentInjector, async () => {
          try {
            console.log('updateAgencyAction', agency);

            const result = await httpsCallable(this.functions, 'agencyUpdate')({ agencyId, agency: Agency.toJSON(agency as Agency) })
            console.log('agencyUpdate', result)

            const data = result.data as { agency: unknown }

            const resultAgency = data?.agency ? Agency.fromJSON(data.agency) : null

            if (!resultAgency) {
              return AgencyActions.updateAgencyFailure({ error: 'Agency not found' })
            }

            return AgencyActions.updateAgencySuccess({
              agencyId,
              agency: Agency.fromJSON({ ...resultAgency, id: agencyId }),
            });
          } catch (error) {
            return AgencyActions.updateAgencyFailure({ error });
          }
        });
      })
    );
  });
}
