import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SkillActions } from './skill.actions';
import { Skill } from '../../models';
import { Firestore, collection, doc, onSnapshot } from '@angular/fire/firestore';
import { Action } from '@ngrx/store';

@Injectable()
export class SkillEffects {
  loadSkills$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillActions.loadSkills),
      mergeMap(({ agencyId }) => {
        console.log('loadSkills agencyId', agencyId)
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'skills'),
            (snapshot) => {
              const skills = snapshot.docs.map((doc) => {
                const data = doc.data()

                return Skill.fromJSON({ ...data, id: doc.id, agency: { id: agencyId } })
              });
              console.log('loadSkills skills', skills)
              subscriber.next(
                SkillActions.loadSkillsSuccess({ skills }),
              );
            },
            (error) => {
              console.error('loadSkills error', error)
              subscriber.next(SkillActions.loadSkillsFailure({ error }));
            },
          );

          // Provide a way of canceling and disposing the listener
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[Skill API] Load Skills Error', error }),
          ),
        );
      }),
    );
  });

  loadSkill$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillActions.loadSkill),
      switchMap(({ skillId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'skills', skillId),
            (snapshot) => {
              const data = snapshot.data();

              const skill = Skill.fromJSON({
                ...data,
                id: snapshot.id,
                agency: { id: agencyId }
              });

              subscriber.next(
                SkillActions.loadSkillSuccess({ skill }),
              );
            },
            (error) => {
              subscriber.next(SkillActions.loadSkillFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  constructor(private actions$: Actions, private firestore: Firestore) { }
}
