import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { firstValueFrom, Observable, of } from 'rxjs';
import { LeadListActions } from './lead-list.actions';
import {
  Firestore,
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { LeadList } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class LeadListEffects {
  loadLeadLists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.loadLeadLists),
      mergeMap(({ agencyId }) => {
        console.log('loadLeadListsAction', agencyId)
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            collection(this.firestore, 'agencies', agencyId, 'leadLists'),
            (snapshot) => {
              const leadLists = snapshot.docs.map((doc) =>
                LeadList.fromJSON({ ...doc.data(), id: doc.id, agencyId }),
              );
              console.log('loaded lead lists', leadLists)
              subscriber.next(
                LeadListActions.loadLeadListsSuccess({ leadLists }),
              );
            },
            (error) => {
              console.error('error loading lead lists', error)
              subscriber.next(LeadListActions.loadLeadListsFailure({ error }));
            },
          );
          return unsubscribe;
        }).pipe(
          catchError((error) =>
            of({ type: '[LeadList API] Load LeadLists Error', error }),
          ),
        );
      }),
    );
  });

  loadLeadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.loadLeadList),
      switchMap(({ leadListId, agencyId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'leadLists', leadListId),
            (snapshot) => {
              const leadList = LeadList.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                agencyId
              });
              subscriber.next(
                LeadListActions.loadLeadListSuccess({ leadList }),
              );
            },
            (error) => {
              subscriber.next(LeadListActions.loadLeadListFailure({ error }));
            },
          );
          return unsubscribe;
        });
      }),
    );
  });

  addLeadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.addLeadList),
      mergeMap(async ({ leadList, agencyId }) => {
        try {
          const apiRes = await this.callUpdateApi(agencyId, leadList)

          if (apiRes.success && apiRes.leadList) {
            return LeadListActions.addLeadListSuccess({
              leadList: { ...apiRes.leadList, agencyId },
            });
          } else {
            throw new Error(apiRes.error?.message || 'Unknown error')
          }
        } catch (error) {
          return LeadListActions.addLeadListFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  removeLeadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.removeLeadList),
      mergeMap(async ({ leadListId, agencyId }) => {
        try {
          await deleteDoc(
            doc(this.firestore, 'agencies', agencyId, 'leadLists', leadListId),
          );
          return LeadListActions.removeLeadListSuccess({ leadListId }); // return removed leadList's id
        } catch (error) {
          return LeadListActions.removeLeadListFailure({ error });
        }
      }),
    );
  });

  updateLeadList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadListActions.updateLeadList),
      mergeMap(async ({ leadListId, leadList, agencyId }) => {
        try {
          leadList.id = leadListId

          const apiRes = await this.callUpdateApi(agencyId, leadList)

          if (apiRes.success) {
            return LeadListActions.updateLeadListSuccess({
              agencyId,
              leadListId,
              leadList,
            });
          } else {
            throw new Error(apiRes.error?.message || 'Unknown error')
          }
        } catch (error) {
          return LeadListActions.updateLeadListFailure({
            error: error as Error,
          });
        }
      })
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private firestore: Firestore,
    private http: HttpClient,
  ) { }

  async callUpdateApi(agencyId: string, leadList: Partial<LeadList>) {
    const token = await this.authService.getIdToken()

    const url = `https://us-east1-${environment.firebase.projectId.split('-dialer')[0]}.cloudfunctions.net/platformDialerUpdateLeadList`

    const result = await firstValueFrom(this.http.post(url, {
      leadList: LeadList.toJSON(leadList as LeadList),
      agencyId,
    }, {
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })) as { success: boolean, leadList: unknown, error?: string }

    if (result?.success) {
      return {
        success: true,
        leadList: LeadList.fromJSON(result.leadList),
      }
    } else {
      return {
        success: false,
        error: new Error(result.error || 'Unknown error'),
      }
    }
  }
}
